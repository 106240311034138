import { http } from "@/helpers/http";
import axios from 'axios';
const baseUrlV2 = "https://c8pqrpii2l.execute-api.ap-southeast-1.amazonaws.com/dev/v1";
const printApi = "https://7coiru45qb.execute-api.ap-southeast-1.amazonaws.com/dev/v1/print-label";
// const printApi = "http://localhost:3000/dev/v1/print-label"; // dev
// const baseUrlV2 = "http://localhost:3000/dev/v1"; // dev
const manualWh = "https://5pwi41xar4.execute-api.ap-southeast-1.amazonaws.com/dev/v1/manual-wh";
// const manualWh = "http://localhost:3000/dev/v1/manual-wh";

export default {
  namespaced: true,
  state: {
    cancelToken: null,
    activeStatus: null,
  },
  getters: {
    activeStatus(state) {
      return state.activeStatus;
    }
  },
  mutations: {
    setActiveStatus(state, activeStatus) {
      state.activeStatus = activeStatus;
    },
    setCancelTokenSource(state, cancelToken) {
      state.cancelTokenSource = cancelToken;
    }
  },

  actions: {

    submitManualCron({ state, commit, rootState }, payload) {
      // prevent duplicate manualCron=
      let manualCron = JSON.parse(localStorage.getItem('manual_cron')) || {};
      let lastRanAt = parseInt(manualCron[payload.data.orderStatus]);
      let currentTime = Date.now();
      const TIME_THRESHOLD = 60 * 1000 * 90; // 90 min
      let makeApiCall = false;

      // last ran time is older than threshold
      if (lastRanAt && (currentTime - lastRanAt) > TIME_THRESHOLD) makeApiCall = true;

      // No records found
      else if (!lastRanAt) makeApiCall = true;

      // show success dialog without API call
      else if (payload.callback) payload.callback(true, null);

      if (makeApiCall) {
        http
          .post(manualWh, payload.data)
          .then((response) => {
            if (payload.callback) payload.callback(true, response.data);
          })
          .catch((error) => {
            if (payload.callback) payload.callback(false);
          });
        manualCron[payload.data.orderStatus] = currentTime;
        localStorage.setItem("manual_cron", JSON.stringify(manualCron));
      }
    },

    submitManualCronTest({ state, commit, rootState }, payload) {
      axios
        .post("http://localhost:5001/manual-cron", payload.data.body, { headers: payload.data.headers })
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchOrderCode({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders/code")
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data.orderCode);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    checkDuplicate({ state, commit, rootState }, payload) {
      http
        .post(baseUrlV2 + "/orders/check-duplicate", payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchOrders({ state, commit, rootState }, payload) {
      if (state.cancelToken) {
        let stateTokenSource = state.cancelToken.source();
        stateTokenSource.cancel('Cancelled by client');
      }
      let token = axios.CancelToken;
      commit('setCancelTokenSource', token);

      let url = baseUrlV2 + (payload.dataSource == 'dd' ? "/orders-v2" : "/orders-es");

      if (payload.dataSource == 'sql') url = 'https://ms1wusi95d.execute-api.ap-southeast-1.amazonaws.com/dev/v1/orders-sql';

      http
        .get(url, {
          params: {
            status: payload.statusCode,
            ExclusiveStartKey: payload.LastEvaluatedKey,
            perPage: payload.perPage,
            onlyError: payload.onlyError
          },
          cancelToken: state.cancelToken
        })
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchSalesAgentOrders({ state, commit, rootState }, payload) {
      if (state.cancelToken) {
        let stateTokenSource = state.cancelToken.source();
        stateTokenSource.cancel('Cancelled by client');
      }
      let token = axios.CancelToken;
      commit('setCancelTokenSource', token);

      let url = baseUrlV2 + "/orders/sales-agent-orders-v2";
      http
        .get(url, {
          params: {
            status: payload.statusCode,
            ExclusiveStartKey: payload.LastEvaluatedKey,
            perPage: payload.perPage,
            onlyError: payload.onlyError
          },
          cancelToken: state.cancelToken
        })
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchSalesAgentOrdersEs({ state, commit, rootState }, payload) {
      if (state.cancelToken) {
        let stateTokenSource = state.cancelToken.source();
        stateTokenSource.cancel('Cancelled by client');
      }
      let token = axios.CancelToken;
      commit('setCancelTokenSource', token);

      let url = baseUrlV2 + "/orders/sales-agent-orders-es";
      http
        .get(url, {
          params: {
            status: payload.status,
            per_page: payload.perPage,
            page: payload.page
          },
          cancelToken: state.cancelToken
        })
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    searchOrders({ state, commit, rootState }, payload) {
      http
        .get(`${baseUrlV2}/orders/search?status=${payload.status}&column=${payload.column}&keyword=${payload.keyword}&salesAgents=${payload.salesAgent}&mode=${payload.mode || "normal"}`)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchOrdersReportV2({ state, commit, rootState }, payload) {
      if (state.cancelToken) {
        let stateTokenSource = state.cancelToken.source();
        stateTokenSource.cancel('Cancelled by client');
      }
      let token = axios.CancelToken;
      commit('setCancelTokenSource', token);

      http
        .post(`${baseUrlV2}/orders/reports-v2`, { query: payload.query, format: payload.format }, { cancelToken: state.cancelToken })
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchOrderDetail({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders/" + payload.id)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    async fetchOrderDetailAsync({ state, commit, rootState }, payload) {
      try {
        const { data } = await http.get(baseUrlV2 + "/orders/" + payload.id);
        return { status: true, data }
      } catch (e) {
        console.log(e);
        return { status: false }
      }
    },

    fetchThaiPostOrders({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders/thaipost-shipments?from=" + payload.from + "&to=" + payload.to)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchOrdersReport({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders/reports?from=" + payload.from + "&to=" + payload.to + "&format=" + payload.format + "&shopId=" + payload.shopId + "&status=" + payload.status)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    createOrder({ state, commit, rootState }, payload) {
      http
        .post(baseUrlV2 + "/orders", payload.order)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    createDraft({ state, commit, rootState }, payload) {
      http
        .post(baseUrlV2 + "/orders/draft", payload.order)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    createFromDraft({ state, commit, rootState }, payload) {
      http
        .post(`${baseUrlV2}/orders/draft/${payload.id}`)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    updateOrder({ state, commit, rootState }, payload) {
      http
        .put(baseUrlV2 + "/orders/" + payload.id, payload.order)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    submitReturnProcessing({ state, commit, rootState }, payload) {
      http
        .post(baseUrlV2 + "/orders/return-processing", payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    saveTaxInvoice({ state, commit, rootState }, payload) {
      http
        .put(baseUrlV2 + "/orders/" + payload.id + "/tax-invoice", { taxInvoice: payload.taxInvoice })
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    deleteOrder({ state, commit, rootState }, payload) {
      http
        .delete(baseUrlV2 + "/orders/" + payload.id)
        .then((response) => {
          if (payload.callback) payload.callback(true);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    deleteOrderPermanently({ state, commit, rootState }, payload) {
      http
        .delete(baseUrlV2 + "/orders/" + payload.id + "/delete")
        .then((response) => {
          if (payload.callback) payload.callback(true);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    flashNotify({ state, commit, rootState }, payload) {
      http
        .post(baseUrlV2 + "/orders/flash-notify", payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    clearImportErrorShipments({ state, commit, rootState }, payload) {
      http
        .delete(baseUrlV2 + "/orders/clear-import-error-shipments")
        .then((response) => {
          if (payload.callback) payload.callback(true);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    exportOrders({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders/export")
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    exportErrorOrders({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders/export-error-shipments")
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    importOrders({ state, commit, rootState }, payload) {
      http
        .post(baseUrlV2 + "/orders/import", payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    updateOrderStatus({ state, commit, rootState }, payload) {
      http
        .put(baseUrlV2 + "/orders/" + payload.id + '/status', payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    massUpdateOrderStatus({ state, commit, rootState }, payload) {
      http
        .put(baseUrlV2 + "/orders/mass-update-status", payload.data)
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    printLabels({ state, commit, rootState }, payload) {
      // api: < N orders
      // bg: >= N orders
      const N = 50;
      let numOrders = payload.data.orders && payload.data.orders.length;
      let mode = (numOrders && numOrders) >= N ? 'bg' : 'api';
      let url = mode == 'bg' ? (baseUrlV2 + "/orders/print") : printApi;

      http
        // .post("http://localhost:3000/dev/v1/print-label", payload.data)
        .post(url, payload.data)
        .then((response) => {
          if (!response.data) response.data = {};
          response.data.mode = mode;
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchBoxSizes({ state, commit, rootState }, payload) {
      http
        .get(baseUrlV2 + "/orders-meta/" + payload.username + "/boxsizes")
        .then((response) => {
          if (payload.callback) payload.callback(true, response.data);
        })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchThpTrackings({ state, commit, rootState }, payload) {
      // debugger;
      // let url = `http://localhost:5000/trackings?barcode=${payload.barcode}&thaipostUsername=${payload.thaiPostUserName}`;
      let url = `https://jobs.proship.co.th/trackings?barcode=${payload.barcode}&thaipostUsername=${payload.thaiPostUserName}`;
      axios.get(url, {
        headers: {
          Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJvc2hpcC1hZG1pbiIsImlhdCI6MTYwNTc3MzU2Mn0.0r6TemJYJGQ-fsdKJndNtdVGtL1n97O3U6MGAxGLXag"
        }
      }).then((response) => {
        if (payload.callback) payload.callback(true, response.data);
      })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchTrackings({ state, commit, rootState }, payload) {
      let url = `https://jobs.proship.co.th/universal-tracking?barcode=${payload.barcode}`;
      axios.get(url, {
        headers: {
          Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJvc2hpcC1hZG1pbiIsImlhdCI6MTYwNTc3MzU2Mn0.0r6TemJYJGQ-fsdKJndNtdVGtL1n97O3U6MGAxGLXag"
        }
      }).then((response) => {
        if (payload.callback) payload.callback(true, response.data);
      })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    },

    fetchThpDetails({ state, commit, rootState }, payload) {
      // debugger;
      // let url = `http://localhost:5000/thaipost-shipment-details?barcode=${payload.barcode}&thaipostUsername=${payload.thaiPostUserName}`;
      let url = `https://jobs.proship.co.th/thaipost-shipment-details?barcode=${payload.barcode}&thaipostUsername=${payload.thaiPostUserName}`;
      http.get(url, {
        headers: {
          Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJvc2hpcC1hZG1pbiIsImlhdCI6MTYwNTc3MzU2Mn0.0r6TemJYJGQ-fsdKJndNtdVGtL1n97O3U6MGAxGLXag"
        }
      }).then((response) => {
        if (payload.callback) payload.callback(true, response.data);
      })
        .catch((error) => {
          if (payload.callback) payload.callback(false);
        });
    }
  },
};

